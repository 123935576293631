let initialState = {
    selectTicket:[],
    ticketPreorderData:null
};

const book = (state = initialState, action) => {

    switch (action.type) {

        case 'SELECT_TICKET_DATA':
            return {...state, selectTicket: action.sources}
        case 'TICKET_PREORDER':
            return {...state, ticketPreorderData: action.sources}
        default:
            return state
    }
}

export default book;
