let initialState = {
    eventDetailData:null,
    checkTicketAvailibility:null
};

const eventDetail = (state = initialState, action) => {

    switch (action.type) {

        case 'EVENT_DATA_DETAIL':
            return {...state, eventDetailData: action.sources}
        case 'TICKET_AVAILIBILITY':
            return {...state, checkTicketAvailibility: action.sources}
        default:
            return state
    }
}

export default eventDetail;
