import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import BookNowComponent from '../components/bookNowComponent';
import { getSelectTicket, getTicketPreorder } from '../../core/actions/action';
import { selectTicketData } from '../../core/actions';

const mapStateToProps = state => {

    return{
        selectTicket:state.Book.selectTicket,
        ticketPreorderData:state.Book.ticketPreorderData
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getSelectTicket:(obj) => {
          dispatch(selectTicketData([]));
            return getSelectTicket(obj)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(selectTicketData(sources.data.total_data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(selectTicketData([]));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        },

        getTicketPreorder:(type,obj) => {
            return getTicketPreorder(type,obj)
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookNowComponent);
