import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class LoadingComponent extends Component{

    render(){

        return(
          <div className="row mt-5 mb-5">
            <div className='col-12 mb-4 Gift_Block'>
                <Card className='bp3-elevation-1'>
                  <div className="bp3-skeleton" style={{height: "3em"}} />
                  <hr/>
                  <div className="bp3-skeleton" style={{height: "3em"}} />
                </Card>
            </div>
            <div className='col-12 mb-4'>
                <Card className='bp3-elevation-1'>
                  <div className="bp3-skeleton" style={{height: "40em"}} />
                </Card>
            </div>

        </div>
        );

    }

}
