import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import BookingDetailComponent from '../components/BookingDetailComponent';
import { getEventDetailData, getEventAvailibility } from '../../core/actions/action';
import { eventData, ticketAvailibility } from '../../core/actions';

const mapStateToProps = state => {
    return{
        eventDetailData:state.EventDetail.eventDetailData,
        checkTicketAvailibility:state.EventDetail.checkTicketAvailibility
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getEventDetailData:(slug) => {
          dispatch(eventData(null));
            return getEventDetailData(slug)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(eventData(sources.data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(eventData(null));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        },

        getEventAvailibility:(id) => {
          dispatch(ticketAvailibility(null));
            return getEventAvailibility(id)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(ticketAvailibility(sources.status));
                }else{
                    dispatch(ticketAvailibility(sources.status));
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
            }).catch((error)=>{
                const sources = error.response;
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailComponent);
