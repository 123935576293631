import React,{Component, Fragment} from 'react';
import {Error} from 'gg-react-utilities';
import BookNowContainer from './container/bookNowContainer';

export default class BookNow extends Component {
      nextPage(route,response){
        this.props.history.push(route,{
          query:response
        });
      }

      componentDidMount(){
        setTimeout(()=>{
          window.scrollTo(0, 0);
        },1)
      }

    render() {
        let error = false;
        if(this.props.location.state===undefined){
          error = true;
         if(this.props.location.state.query.ticketType.length===0){
            error = true;
          }
        }
        return (
          <Fragment>
            {error ?
              <Error/>
            :
            <BookNowContainer
              eventDetail = {this.props.location.state.query.eventDetail}
              eventId = {this.props.location.state.query.eventId}
              eventSlug = {this.props.location.state.query.eventSlug}
              back={this.props.history}
              eventName={this.props.location.state.query.eventName}
              paymentStatus={this.props.location.state.query.paymentStatus}
              ticketType={this.props.location.state.query.ticketType}
              nextPage={(route,response)=>{
                this.nextPage(route,response);
              }}/>
            }
          </Fragment>
        );
    }
}
