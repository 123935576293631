import React,{ Component } from 'react';
import BookingHistoryComponent from './container/bookingHistoryContainer';
import {Error} from 'gg-react-utilities';

export default class BillHistory extends Component{

  componentDidMount(){
    setTimeout(()=>{
      window.scrollTo(0, 0);
    },1)
  }

    render(){
      try {
        return(
          <BookingHistoryComponent

            back={this.props.history}
          />
        )
      } catch (e) {
        return(
          <Error />
        )
      }
    }
}
