import React, {Component, Fragment} from 'react';
import {Button, Icon, Intent, Tab, Tabs, Position, Checkbox, Alignment} from "@blueprintjs/core";
import {DateRangeInput, DateInput, IDateFormatProps} from '@blueprintjs/datetime';
import InputRange from 'react-input-range';
import {Loader, Helper} from 'gg-react-utilities';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import Loading from './loading';
import AllPanel from './allPanel';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: {
                min: 0,
                max: 0,
            },
            page: 1,
            data: [],
            active: false,
            isDateOpen: false,
            isChecked: false,
            dateType: '',
            Date: ['today', 'tomorrow', 'weekend', 'upcoming '],
            startDate: new Date(),
            endDate: null,
            selectedDay: 0,//no day is selected
            update: false
        };
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.getRangeDefaultValue = this.getRangeDefaultValue.bind(this);
    }

    getEvent() {
        if ((+this.state.page) + 1 < this.props.eventList.last_page) {
            this.props.getEventData(this.state.page, this.state.value, this.state.selectedDay);
        }
    }

    getQueryStringValue = (key) => {
        return +decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    componentDidMount() {
        this.props.getPriceData();
        let min = this.getQueryStringValue("min");
        let max = this.getQueryStringValue("max");
        let selectedDay = +this.getQueryStringValue("selectedDay");
        let page = this.getQueryStringValue("page");

        page = (+page === 0) ? 1 : +page;
        let curState = this.state

        curState.value.min = min;
        curState.value.max = max
        curState.page = 1;
        curState.selectedDay = selectedDay;
        //      }


        this.props.getEventData(curState.page, curState.value, curState.selectedDay)
        this.setState(curState);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.eventList !== null) {
            //if we are comming from detail page
            let curState = this.state;
            curState.page = nextProps.eventList.current_page
            curState.data = this.state.data.concat(nextProps.eventList.data)

            let ids = [];
            let dataList = []
            for (let i = 0; i < curState.data.length; i++) {
                if (!ids.includes(curState.data[i].event_id)) {
                    ids.push(curState.data[i].event_id);
                    dataList.push(curState.data[i])
                }
            }
            curState.data = dataList;
            if (nextProps.eventList.last_page >= this.state.page) {
                this.setState(curState);
            }
        }
    }

    event_filter() {
        this.setState({
            active: !this.state.active
        })
    }

    fetchMoreData() {
        if (this.props.eventList !== null) {
            this.props.getEventData(this.state.page + 1, {
                min: this.state.value.min,
                max: this.state.value.max
            }, this.state.selectedDay);
            this.setState({page: this.state.page + 1})
        }
    }

    //this is toupdate page url
    updateUrl = () => {
        let url = window.location.href.split("?")[0];
        let qstr = this.getFilterDataAsQstr();
        window.history.pushState(null, "GG Events", url + qstr);
    }

    handleChange(e, type) {
        if (e.target.checked) {
            this.setState({dateType: e.target.value, data: [], page: 1}, () => {
            })
        };
    }

    handleDaySelect = (value) => {
        this.setState({selectedDay: value});
    };

    //this is to check filter is applied or not
    hasFilter = () => {
        let count = 0;
        count += this.state.value.min
        count += this.state.value.max;
        count += this.state.selectedDay
        return (count > 1);
    }

    getFilterDataAsQstr() {
        let str = "?&min=" + this.state.value.min;
        str += "&max=" + this.state.value.max;
        str += "&page=" + this.state.page;
        str += "&selectedDay=" + this.state.selectedDay;
        return str
    }

    getRangeDefaultValue = () => {
        let min = +this.state.value.min;
        let max = +this.state.value.max;
        //if 0 on state
        if ((min + max) <= 0) {
            min = this.getQueryStringValue("min");
            max = this.getQueryStringValue("max");
        }
        if ((min + max) <= 0) {            
            if (this.props.priceData !== null && this.props.priceData !== undefined) {
                min = this.props.priceData.min_price;
                max = this.props.priceData.max_price;
            }
        }
        return {
            min: min,
            max: max
        }
    }

    render() {
        let rangValue = this.getRangeDefaultValue()
        const {eventList, priceData} = this.props;
        let futureMonth = moment(new Date()).add(1000, 'M');
        return (
            <Fragment>
                <div className="event_banner">
                    <div className="container pt-4 pb-5">
                        <div className="row d-flex justify-content-end booking-btn">
                            <a className="main-top-btn-first pt-2 bp3-button" href="/booking-history">Booking
                                History</a>
                        </div>
                    </div>
                </div>
                <div className="container pt-4 pb-5">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 ">
                            <div className="">
                                <div className="w-100">
                                    <Button
                                        className={this.state.active
                                            ? "floating-menu bp3-button bp3-small hidden-xl hidden-lg hidden-md visible-sm visible-xs active"
                                            : "floating-menu bp3-button bp3-small hidden-xl hidden-lg hidden-md visible-sm visible-xs"}
                                        intent={Intent.PRIMARY}
                                        onClick={this.event_filter.bind(this)}
                                    >
                                        {this.state.active ? <Icon icon="cross" iconSize={28}/> :
                                            <Icon icon="filter-list" iconSize={28}/>}
                                    </Button>
                                    <div className="event_filter">
                                        <div className="filter">
                                            <h1>Events</h1>
                                            <div className="border-theme col-12 mt-3 pb-3 font-20">
                                                <div
                                                    className="text-left d-flex justify-content-between text-dark pr-2 align-items-center part_title">
                                                    <h3>Price</h3>
                                                </div>
                                                <div className="my-4 px-4">
                                                    <InputRange
                                                        stepSize={50}
                                                        className="my-4"
                                                        formatLabel={value => `₹ ${value}`}
                                                        minValue={priceData !== null && priceData !== undefined ? priceData.min_price : 1}
                                                        maxValue={priceData !== null && priceData !== undefined ? priceData.max_price : 5000}
                                                        value={rangValue}
                                                        onChange={value => {
                                                            this.setState({value: value})
                                                        }}
                                                    />
                                                </div>
                                                <br/>
                                                <h3 className="d-flex justify-content-between text-dark pr-2 align-items-center part_title">Date</h3>
                                                <div>
                                                    <Checkbox checked={this.state.selectedDay == 1} label="Today"
                                                              onChange={() => this.handleDaySelect(1)}/>
                                                    <Checkbox checked={this.state.selectedDay == 2} label="Tomorrow"
                                                              onChange={() => this.handleDaySelect(2)}/>
                                                    <Checkbox checked={this.state.selectedDay == 3} label="Week end"
                                                              onChange={() => this.handleDaySelect(3)}/>
                                                    <Checkbox checked={this.state.selectedDay == 4} label="Upcoming"
                                                              onChange={() => this.handleDaySelect(4)}/>
                                                </div>

                                                <Button className='bp3-button bp3-secondary bp3-small pull-left '
                                                        text='Reset Filter'
                                                        onClick={() => {
                                                            this.props.getEventData(1, {min: 0, max: 1}, 0);
                                                            this.setState({
                                                                value: {
                                                                    min: this.props.priceData.min_price,
                                                                    max: this.props.priceData.max_price
                                                                },
                                                                page: 1,
                                                                data: [],
                                                                selectedDay: 0
                                                            })
                                                            window.history.pushState(null, "GG Events", window.location.href.split("?")[0]);

                                                        }}/>
                                                <Button className='bp3-button bp3-intent-primary bp3-small pull-right '
                                                        text='Apply Filter'
                                                        onClick={() => {
                                                            //we are goint to applie the filter ,so set page to one,clear the data
                                                            this.updateUrl();
                                                            this.props.getEventData(1, this.state.value, this.state.selectedDay);
                                                            this.setState({page: 1, data: []},);
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 px-s-0 px-sm-2 px-md-0 px-lg-0 px-xl-0 px-0 pt-4">
                            <div className="event_parts px-2">
                                <div className="Enent_Tabs">
                                    <Tabs
                                        animate={this.state.animate}
                                        id="TabsExample"
                                        key={this.state.vertical ? "vertical" : "horizontal"}
                                        renderActiveTabPanelOnly={this.state.activePanelOnly}
                                        vertical={this.state.vertical}
                                    >
                                        <Tab id="all" title="All" panel={
                                            eventList === null ?
                                                <Loading/>
                                                :
                                                <InfiniteScroll
                                                    dataLength={this.state.data.length}
                                                    next={this.fetchMoreData}
                                                    hasMore={true}
                                                    loader={eventList.last_page > this.state.page
                                                        ?
                                                        this.state.data.length > 0
                                                            ?
                                                            <div className='mt-1'><Loader/></div>
                                                            : null
                                                        : null
                                                    }
                                                >
                                                    <AllPanel paramString={this.getFilterDataAsQstr()}
                                                              data={this.state.data} price={this.state.value}
                                                              startDate={this.state.startDate}
                                                              endDate={this.state.endDate}/>
                                                </InfiniteScroll>
                                        }/>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
