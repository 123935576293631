let initialState = {
    value:{
        min:1,
        max:100
    },
    page:1,
    selectedDay:0
};
const Filters = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VALUE':
            state.value=action.value
            break;
        case 'SET_PAGE':
            state.page=action.page;
            break;
        case 'SET_DAY':
            state.selectedDay=action.selectedDay;
            break;
    }
    return state;
}

export default Filters;
