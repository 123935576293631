import {ssoInstance} from 'gg-react-utilities';
import Config from '../../Config.js'


export const getEventData = (page,price,key) => {
    let keyname=["today","tomorrow","weekend","upcoming"];
    if(key>0){
        key=keyname[key-1];
    }else{
        key='';
    }
    // let date = (startDate===null && endDate === null) || (startDate===null || endDate === null) ?'':'&start_date='+startDate+'&end_date='+endDate;
    let min=+price.min;
    let max=+price.max;
    if(min > max){
        let t=min;
        min=max;
        max=min;
    }
    let priceParam ="";
    if(min !==max  &&  max>0){
        priceParam='&min_price='+min+'&max_price='+max;
    }
    //let url=Config.API_URL + 'event?page='+page+priceParam+"&"+key;
    return ssoInstance.get(Config.API_URL + 'event?page='+page+priceParam+"&"+key);
}

export const getCategoryData = () => {
    return ssoInstance.get(Config.API_URL + 'categories');
}

export const getEventAvailibility = (id) => {

    return ssoInstance.post(Config.API_URL + 'event/check-availability?event_id='+id);
}

export const getEventDetailData = (event_slug) => {

    return ssoInstance.get(Config.API_URL + 'event/'+event_slug);
}

export const getPriceData = () => {

    return ssoInstance.get(Config.API_URL + 'event/filter/value');
}

export const getSelectTicket = (obj) => {

    return ssoInstance.post(Config.API_URL + 'event/select-ticket',obj);
}

export const getTicketPreorder = (type,obj) => {
    let url = type==='0'?'booking-offline':'booking-proceed';
    return ssoInstance.post(Config.API_URL + 'booking/'+url,obj);
}

export const getTicketBookingHistory = (page) => {

    return ssoInstance.get(Config.API_URL + 'booking/history?page='+page);
}
