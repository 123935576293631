import React from 'react';
import {Route} from 'react-router-dom';
import {Header, Footer, ScrollTop} from 'gg-react-utilities';

const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
        <div className="Events">
            <Header/>
            <Component {...props} />
            <ScrollTop/>
            <Footer/>
        </div>
    )}/>
);

export default PrivateRoute;
