import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import BookingHistoryComponent from '../component/bookingHistoryComponent';
import { getTicketBookingHistory } from '../../core/actions/action';
import { ticketBookingHistoryData } from '../../core/actions';

const mapStateToProps = state => {
    return{
        ticketBookingHistoryData:state.BookingHistory.ticketBookingHistoryData,
    }
}

const mapDispatchToProps = dispatch => {

    return {

        getTicketBookingHistory:(page)=>{
          dispatch(ticketBookingHistoryData(null))
              getTicketBookingHistory(page)
              .then((res)=>{
                  const sources = res.data;
                  if(sources.status===true){
                      dispatch(ticketBookingHistoryData(sources.data))
                  }else{
                    dispatch(ticketBookingHistoryData(null))
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                  }
                }).catch((error)=>{
                    const sources = error.response;
                    //console.log("error",error);
                    if(sources===undefined){
                      Toaster.create({position: Position.TOP}).show({message:'Something went wrong 132456456!',intent: Intent.DANGER});
                    }
                    else if(sources.status!==401){
                      sources.data.error.map((res)=>{
                        Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                        return res;
                      })
                    }else{
                      Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                });
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistoryComponent);
