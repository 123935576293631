let initialState = {
    eventList:null,
    priceData:null
};

const home = (state = initialState, action) => {

    switch (action.type) {

        case 'EVENT_DETAIL':
            return {...state, eventList: action.sources}
        case 'PRICE_DATA':
            return {...state, priceData: action.sources}
        default:
            return state
    }
}

export default home;
