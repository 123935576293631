import React from 'react';
import {PageTitle, ssoInstance} from 'gg-react-utilities';
import {Dialog, Intent, Position, Toaster} from "@blueprintjs/core";
import TopBar from "../core/components/TopBar";
import {Link} from "react-router-dom";
import Config from '../Config';

export default class index extends React.Component {


    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
      let status = this.props.match.params.status;

      let query = new URLSearchParams(this.props.location.search ? this.props.location.search : "");
      let orderId = query.get('orderid');
      if(status==="success"){
        ssoInstance.post(Config.API_URL + 'booking/place/order?orderId='+orderId).then((res)=>{

            const sources = res.data;
            if (sources.status === true) {
              Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
            } else {
              Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
            }
            setTimeout(()=>{
              this.props.history.push({
                pathname: '/booking-history',
              })
            },2000)

        }).then(function(json) {

        }).catch(function(error) {
            let sources = error.response;

            Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
        });
      }else{
        setTimeout(()=>{
          this.props.history.push({
            pathname: '/booking-history',
          })
        },2000)

      }


    }

    render() {
        let status = this.props.match.params.status;

        let query = new URLSearchParams(this.props.location.search ? this.props.location.search : "");
        let orderId = query.get('orderid');


        let title = "";
        let message = "";
        let link = "";
        let image = "";

        if (status === "success") {
            title = "Your ticket booking #"+orderId+ ' is ' +status;
            message = "Order has been placed successfully and an invoice has been emailed to the customer.";
            link = <Link to="/booking-history" className="pb-4"><b>VIEW ORDERS</b></Link>;
            image = require('./images/success.svg');
        } else if (status === "failed") {
            title = "Your ticket booking #"+orderId + ' is ' +status;
            message = "You might receive a message from your bank that the payment could not be processed.";
            link = <Link to="/=" className="pb-4"><b>GO TO HOME</b></Link>;
            image = require('./images/cancel.svg');
        }

        return (
            <div className='container'>
              <TopBar title={"Payment"} backClick={this.props.back}
                breadcrumbs={[
                  {label:'Payment',to:''},
                ]}/>
              <PageTitle title={'Payment | Event | Global Garner'} />
                    <div className={"mh-100"}>
                        <Dialog
                            isOpen={true}
                            lazy={false}
                            className="bp3-dialog-large payment-status"
                            canOutsideClickClose={false}
                            canEscapeKeyClose={true}
                        >
                            <div className="mg-brand-payments">
                                <div className="">
                                    <div className="">
                                        <div className="">
                                            <div className="paymentWrapper">
                                                <div className="upperPaymentWrapper"></div>
                                                <div className="middlePaymentWrapper">
                                                    <div className="circleWrapper">
                                                        <div className="iconWrapper">
                                                            <img src={image} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-8 lowerPaymentWrapper m-auto">
                                                    <h3>{title}</h3>
                                                    <p>{message}</p>
                                                    {link}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
            </div>
        );
    }
}
