import React, {Component, Fragment} from 'react';
import {Radio, RadioGroup, Button, Intent, Position, Toaster} from "@blueprintjs/core";
import Select from 'react-select';
import {PageTitle, Auth} from 'gg-react-utilities';
import TopBar from "../../core/components/TopBar";
import {renderErrorComponent} from "../../core/utils/helper";

const designationOptions = [
	{ value: 'MCP', label: 'MCP' },
	{ value: 'CP', label: 'CP' }
]

export default class BookNowComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoggedIn:null,
          paymentStatus:false,
          ticketType:null,
          firstName:Auth.user().fname,
          lastName:Auth.user().lname,
          contactNo:Auth.user().mobile,
          email:Auth.user().email,
		  location:'',
		  designation:'',
		  recruited_by:'',
          ticketError:'',
          firstNameError:'',
          lastNameError:'',
          contactNoError:'',
          emailError:'',
		  locationError:'',
		  designationError:'',
		  recruitedByError:'',
          ticketData:[
            {
              value:1,
              label:'1'
            }
		  ]
        }
    }

    componentDidMount() {
      if(this.props.ticketType.length===1){
        this.setState({ticketType:this.props.ticketType[0].type},()=>{
          let obj = {
            event_id:this.props.eventId,
            ticket_type:this.state.ticketType
          }
          this.props.getSelectTicket(obj)
        })
      };
      this.checkAuth();
    }

    componentWillReceiveProps(nextProps) {

    }

    async checkAuth(){
      setTimeout(()=>{
        Auth.isLoggedInAsync().then((res) => {
          this.setState({isLoggedIn:res},()=>{
            if(this.state.isLoggedIn!==true){
              Auth.login(true)
            }
          })
        });
      },1000)
    }

    changeType(e) {
      this.setState({ticketType:e.target.value},()=>{
        let obj = {
          event_id:this.props.eventId,
          ticket_type:this.state.ticketType
        }
        this.props.getSelectTicket(obj)
      })
    }

    bookTicket(paymentStatus){
      let obj = {
        event_id:this.props.eventId,
        first_name:this.state.firstName,
        last_name:this.state.lastName,
        email:this.state.email,
        contact_number:this.state.contactNo,
		location:this.state.location
	  }
	  
		if(this.props.eventDetail.show_designation_field) {
			obj.designation = this.state.designation
		}
	  
		if(this.props.eventDetail.show_recruited_by_field) {
			obj.recruited_by = this.state.recruited_by
		}

      let price = 0
      this.props.ticketType.map((res)=>{
        if(res.type===this.state.ticketType){
          price = res.amount;
        };
      })
      if(paymentStatus==='1'){
        obj.ticket_type = this.state.ticketType
        obj.ticket_amount = price
      }
	
	  this.setState({paymentStatus:true},()=>{
        this.props.getTicketPreorder(paymentStatus,obj).then((res) => {
            const sources = res.data;
            // this.setState({paymentStatus:false});
            if(paymentStatus==="1"){
              if (sources.status === true) {
                const paywithpayubiz = sources.data;
                let form = document.createElement("form");
                form.setAttribute("method", 'POST');
                form.setAttribute("action", paywithpayubiz.url);
                for (let key in paywithpayubiz) {
                  let hiddenField = document.createElement("input");
                  hiddenField.setAttribute("type", "hidden");
                  hiddenField.setAttribute("name", key);
                  hiddenField.setAttribute("value", paywithpayubiz[key]);
                  form.appendChild(hiddenField);
                }
                //
                document.body.appendChild(form);
                form.submit();
              }
            }else{
              if (sources.status === true) {
                setTimeout(()=>{
                  this.props.nextPage('booking-history')
                },1000)
                Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
              }else{
                Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
              }

            }


        }).catch((error) => {
            this.setState({paymentStatus:false});
            const sources = error.response;

            if(sources.data.error.ticket_type){
                this.setState({ticketError:sources.data.error.ticket_type})
            }else {
              this.setState({ticketError:''})
            }

            if(sources.data.error.first_name){
                this.setState({firstNameError:sources.data.error.ticket_type})
            } else {
              this.setState({firstNameError:''})
            }

            if(sources.data.error.last_name){
                this.setState({lastNameError:sources.data.error.last_name})
            } else {
              this.setState({lastNameError:''})
            }

            if(sources.data.error.contact_number){
                this.setState({contactNoError:sources.data.error.contact_number})
            } else {
              this.setState({contactNoError:''})
            }

            if(sources.data.error.email){
                this.setState({emailError:sources.data.error.email})
            } else {
              this.setState({emailError:''})
            }

            if(sources.data.error.location){
              this.setState({locationError:sources.data.error.location})
            } else {
              this.setState({locationError:''})
			}
			
			if(sources.data.error.designation){
				this.setState({designationError:sources.data.error.designation})
			} else {
				this.setState({designationError:''})
			}

			if(sources.data.error.recruited_by){
				this.setState({recruitedByError:sources.data.error.recruited_by})
			} else {
				this.setState({recruitedByError:''})
			}

        });
      })

    }

    render() {
        const {ticketType, selectTicket, paymentStatus} = this.props;
        return (
            <Fragment>
                <div className="Book_Now">
                    <div className="container py-5">
                      <PageTitle title='Register now | Event| Global Garner' />
                      <TopBar backClick={this.props.back}
                        breadcrumbs={[
                          {label:this.props.eventName,to:'/event/'+this.props.eventSlug},
                          {label:'Register Now',to:''},
                        ]}/>
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-4">
                                <div className="Tickets">
                                    <div className="">
                                      {paymentStatus!=='0'
                                        ?
                                        <Fragment>

                                          <h2>Select Ticket Type</h2>
                                          <RadioGroup
                                            className="d-sm-flex option-type mt-4"
                                            onChange={(e) => this.changeType(e)}
                                            name="type"
                                            selectedValue={this.state.ticketType}
                                            >
                                              {
                                                ticketType.map((res,i)=>{
                                                  let style = '';
                                                  if(i>0){
                                                    style ="ml-sm-4"
                                                  }
                                                  return(
                                                    <Radio
                                                      key={res.id}
                                                      label={res.type+" (₹"+res.amount+")"}
                                                      value={res.type}
                                                      className={style}
                                                    />
                                                  )
                                                })
                                              }

                                          </RadioGroup>
                                          {renderErrorComponent(this.state.ticketError)}
                                        </Fragment>
                                        :
                                        null
                                      }


                                        <div className="my-3" style={{display:"none"}}>
                                            <Select
                                                className="w-100"
                                                placeholder="No. Of Tickets"
                                                options={this.state.ticketData}
                                                value={this.state.ticketData[0]}
                                                onChange={(value)=>{

                                                }}
                                            />
                                        </div>
                                        
                                        <h2>Contact Details</h2>
                                        <h4>All communication from GG will be sent on the shared details</h4>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-sm-6 col-12 mb-4'>
                                                <input
                                                    type="text"
                                                    className="bp3-input"
                                                    value={this.state.firstName}
                                                    onChange={(e)=>{
                                                      this.setState({firstName:e.target.value})
                                                    }}
                                                    required
                                                />
                                                <label className="form-control-placeholder">First Name</label>
                                                {renderErrorComponent(this.state.firstNameError)}
                                            </div>
                                            <div className='col-md-6 col-sm-6 col-12 mb-4'>
                                                <input
                                                    type="text"
                                                    className="bp3-input"
                                                    value={this.state.lastName}
                                                    onChange={(e)=>{
                                                      this.setState({lastName:e.target.value})
                                                    }}
                                                    required
                                                />
                                                <label className="form-control-placeholder">Last Name</label>
                                                {renderErrorComponent(this.state.lastNameError)}
                                            </div>
                                            <div className='col-md-6 col-sm-6 col-12 mb-4'>
                                                <input
                                                    type="number"
                                                    className="bp3-input"
                                                    value={this.state.contactNo}
                                                    onChange={(e)=>{
                                                      this.setState({contactNo:e.target.value})
                                                    }}
                                                    required
                                                />
                                                <label className="form-control-placeholder">Contact Number</label>
                                                {renderErrorComponent(this.state.emailError)}
                                            </div>
                                            <div className='col-md-6 col-sm-6 col-12 mb-4'>
                                                <input
                                                    type="email"
                                                    className="bp3-input"
                                                    value={this.state.email}
                                                    onChange={(e)=>{
                                                      this.setState({email:e.target.value})
                                                    }}
                                                    required
                                                />
                                                <label className="form-control-placeholder">Email Address</label>
                                                {renderErrorComponent(this.state.contactNoError)}
                                            </div>
                                            <div className='col-md-12 col-sm-12 col-12 mb-4'>
                                                <input
                                                    type="text"
                                                    className="bp3-input"
                                                    value={this.state.location}
                                                    onChange={(e)=>{
                                                      this.setState({location:e.target.value})
                                                    }}
                                                    required
                                                />
                                              <label className="form-control-placeholder">Location</label>
                                                {renderErrorComponent(this.state.locationError)}
                                            </div>

											{
												this.props.eventDetail.show_designation_field ?
													<div className='col-md-6 col-sm-6 col-12 mb-4'>
														<Select
															className="w-100"
															placeholder="Designation"												
															options={designationOptions}
															onChange={(e)=>{
																this.setState({designation:e.value})
															}}
														/>
														{renderErrorComponent(this.state.designationError)}
													</div> 
												: ''
											}

											{
												this.props.eventDetail.show_recruited_by_field ?
													<div className='col-md-6 col-sm-6 col-12 mb-4'>
														<input
															type="text"
															className="bp3-input"
															value={this.state.recruited_by}
															onChange={(e)=>{
															this.setState({recruited_by:e.target.value})
															}}
															required
														/>
														<label className="form-control-placeholder">Recruited By</label>
														{renderErrorComponent(this.state.recruitedByError)}
													</div>
												: ''
											}

                                            {paymentStatus==='0'
                                            ?
                                            <div className='col-md-12 col-sm-12 col-12 mb-2'>
                                              <Button
                                                className='bp3-button bp3-intent-primary pull-right '
                                                text='Register Now'
                                                loading={this.state.paymentStatus}
                                                onClick={()=>{this.bookTicket(paymentStatus)}}/>
                                            </div>
                                            :
                                            null
                                          }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {paymentStatus!=='0'
                              ?
                            <div
                                className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 px-sm-3 px-md-0 px-lg-0 px-xl-0 px-0 px-s-3">
                                <div className="Payment">
                                    <div className="col-xl-12 mb-5 px-0">
                                        <h2 className="title mb-4">Payment Details</h2>
                                        {this.state.ticketType===null || selectTicket.length===0?
                                          <Fragment>
                                            <div className="bp3-skeleton mb-4" style={{height: "2em"}} />
                                            <div className="bp3-skeleton mb-4" style={{height: "2em"}} />
                                            <hr/>
                                            <div className="bp3-skeleton mb-4" style={{height: "2em"}} />
                                          </Fragment>
                                        :
                                        selectTicket.map((res)=>{
                                          return(
                                            <Fragment key={res.code}>
                                              {res.code==='total'?
                                                <Fragment>
                                                  <hr/>
                                                  <div className="d-flex align-items-center justify-content-between">
                                                    <h4><b>{res.title}</b></h4>
                                                    <h4><b>&#8377; {res.value}</b></h4>
                                                  </div>
                                                </Fragment>
                                                :
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <h4>{res.title}</h4>
                                                  <h4>&#8377; {res.value}</h4>
                                                </div>
                                              }
                                            </Fragment>
                                          )
                                        })
                                        }
                                        {
                                          this.state.ticketType===null || selectTicket.length===0
                                          ?
                                          <Button
                                            className='bp3-button bp3-intent-primary pull-right mb-4'
                                            text='Proceed to pay'
                                            loading={this.state.paymentStatus}
                                            disabled/>
                                          :
                                          <Button
                                            className='bp3-button bp3-intent-primary pull-right mb-4'
                                            text='Proceed to pay'
                                            loading={this.state.paymentStatus}
                                            onClick={()=>{this.bookTicket(paymentStatus)}}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            :null
                          }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
