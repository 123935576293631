import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

export default class AllPanel extends Component{
    constructor(props){
        super(props);

        this.state = {
            activePanelOnly: false,
            animate: true,
            vertical: false
        };
    }

    renderDate(time){
      time = moment(time).format('ll').split(', ')
      time = time[0].split(' ');
      return(
        <div style={{
          minWidth: '44px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'flex-start'
      }}>
            <h2>{time[1]}</h2>
            <h4>{time[0]}</h4>
        </div>
      )
    }
    //this is to render ticket button
    renderStatusMessage=(event_date,res)=>{
      if(res==null)return null;
      let isExpire=(new Date().getTime()>event_date.getTime())
      if(!isExpire){
        //check ticekts are available or not
        let count=+res.total_ticket
        if(count>0){
          return  <div >
              <h6 className={(+res.total_ticket > 0)?"text-success":"text-danger" }>{res.ticket_status}</h6>
            </div>
        }
      }
      return  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>;
    }

    render(){

      const {data, price, startDate, endDate} = this.props;
        return(
            <div className="row">
              {data.length===0?
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2">
                    <div className='border'>
                      <center className='mb-5 mt-5 '>
                        <img src={require('../images/calendar.svg')} alt=''/>
                        <p className='mt-3 mb-2'>No events found!</p>
                      </center>
                    </div>
                  </div>
                :
                data.map((res, i)=>{
                  // let date = (startDate===null || endDate === null) || (startDate===null && endDate === null) ? '' : '&startDate='+moment(startDate).format('YYYY-MM-DD')+'&endDate='+moment(endDate).format('YYYY-MM-DD');
                  // let priceRange = price.max === 1 || price.min === 0 ?'/':'/?min='+price.min+'&max='+price.max;
                  let url = '/event/'+res.slug
                  if(res.is_online_payment_status==='0'|| (res.is_online_payment_status==='1' && res.ticket_type.length > 0)){
                    return (
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4" key={res.event_id+'/'+i}  data-id={res.event_id}>
                        <Link to={url+this.props.paramString} >
                          <div className="event_block">
                              <img src={res.image} alt={res.event_name} className="img-fluid"
                              onError={(ev)=>{
                              ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                              }}/>
                              <div className="d-flex align-items-center p-3">
                                  {this.renderDate(res.start_time)}
                                  <div className="pl-3">
                                      <h5>{res.event_name}</h5>
                                      <h6>{(res.city+', '+res.state).substring(0,25)}</h6>
                                      <h6 className='desc'>{(res.event_description.length>15)?res.event_description.substring(0,15)+'...':res.event_description}</h6>
                                      {
                                        res.ticket_type.length > 0 ?
                                        <h6> &#8377; {res.ticket_type[res.ticket_type.length-1].amount} {res.ticket_type.length > 1?'Onwards':''}</h6>
                                        :
                                        <h6 className='text-success'>Free</h6>
                                      }
									  
                                     {this.renderStatusMessage(new Date(res.end_time.replace(" ","T")),res)}
                                  </div>
                              </div>
							  	<div className='time'>
									  <div className="event-time-label">Start Time : </div>
									  <div>{ moment(res.start_time).format('Do MMM YYYY hh:mm A') }</div>
								</div>
								<div className='time'>
									<div className="event-time-label">End Time : </div>
									<div>{ moment(res.end_time).format('Do MMM YYYY hh:mm A') }</div>
								</div>
                          </div>
                        </Link>
                      </div>
                    );

                  }

              })}
            </div>
        );
    }
}
