import React,{Fragment} from 'react';

export const renderErrorComponent = (errors) => {

    if (Array.isArray(errors)) {
        return errors.map((error, key) => {

            return <Fragment key={Math.floor((Math.random() * 100000) + 1) + "" + key}><span
                className="text-danger font-13 text-capitalize">* {error}</span>
                <br/>
            </Fragment>
        });

    } else {
        return <span className="text-danger text-capitalize">{errors}</span>
    }

}
