import { combineReducers } from 'redux';
import Home from './home';
import Book from './book';
import EventDetail from './eventDetail';
import BookingHistory from './bookingHistory';
import Filters from './Filters'
const event = combineReducers({
    Home,
    Book,
    EventDetail,
    BookingHistory,
    Filters
})

export default event;
