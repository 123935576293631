import React, {Component} from 'react';
import BookingDetailComponent from "./container/BookingDetailContainer";

export default class BookingDetail extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    nextPage(route,response){
      this.props.history.push(route,{
        query:response
      });
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
        return(
          <BookingDetailComponent eventSlug={this.props.match.params.event_slug}
            back={this.props.history}
            filter = {this.props.location.search}
            nextPage={(route,response)=>{
              this.nextPage(route,response);
            }}/>
        );
    }
}
