let initialState = {
    ticketBookingHistoryData:null
};

const bookingHistory = (state = initialState, action) => {

    switch (action.type) {

        case 'TICKET_BOOKING_HISTORY_DATA':
            return {...state, ticketBookingHistoryData: action.sources}
        default:
            return state
    }
}

export default bookingHistory;
