import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import { KeyboardArrowLeftRounded } from '@material-ui/icons';

export default class SecondayHeader extends Component{
    constructor(props){
      super(props);
      this.state={
        breadcrumbs:props.breadcrumbs
      }
    }
    render(){
        // let firstBreadcrumbs = "";
        //
        // if (this.state.breadcrumbs === null) firstBreadcrumbs = "bold";
        return(
          <Fragment>
            <div className="pt-3 pb-3 row d-flex align-items-center justify-content-start topBar pl-2">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <ul className="bp3-breadcrumbs mb-breadcrumbs">
                        {
                          this.props.backIcon || this.props.backIcon===undefined ?
                                <li>
                                    <span className="back-icon hidden-xs"
                                          onClick={() =>{
                                            if(this.props.static===true){
                                              window.location.href= '/';
                                            }else{
                                              this.props.backClick.goBack()
                                            }}}
                                            >
                                        <KeyboardArrowLeftRounded/>
                                    </span>
                                </li>
                        :null}
                        <li>
                            <Link className={"bp3-breadcrumbs bold"} to="/">Events</Link>
                        </li>
                        {
                            this.state.breadcrumbs!==undefined ?
                                this.state.breadcrumbs.length>0 ?
                                    this.state.breadcrumbs.map((nav, key) => {
                                        let lastBreadcrumbs = "";
                                        // if (this.state.breadcrumbs.length === key + 1) lastBreadcrumbs = "bold";
                                        return (
                                            <li key={key}>
                                                <Link className={"bp3-breadcrumbs " + lastBreadcrumbs}
                                                      to={nav.to}>
                                                    {nav.label}
                                                </Link>
                                            </li>
                                        )
                                    })
                                    : null
                                : null
                        }
                    </ul>
                </div>
              {this.props.rightMenu?
                this.props.rightMenu
                :
                null
              }
          </div>
        </Fragment>
        );

    }

}
