import React, {Component, Fragment} from 'react';
import { Card } from "@blueprintjs/core";
import {Link} from 'react-router-dom';
import {PageTitle, Auth, NoLogin, Loader, Helper} from 'gg-react-utilities';
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from './loading';
import TopBar from "../../core/components/TopBar";

export default class ProductComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          billType:1,
          page:1,
          data:[],
          isLoggedIn:null
        }
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }

    componentWillMount(){
      this.checkAuth();
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.ticketBookingHistoryData!==null){
        if(nextProps.ticketBookingHistoryData.last_page>=this.state.page){
          this.setState({
              data: this.state.data.concat(nextProps.ticketBookingHistoryData.data),
          })
      }}
    }

    changeBillType(e){
      this.setState({billType:Number(e.target.value)})
    }

    fetchMoreData(){
      if(this.props.ticketBookingHistoryData!==null){
        if(this.props.ticketBookingHistoryData.total!==this.props.ticketBookingHistoryData.to){
          this.setState({page:this.state.page+1}, () => {
            this.props.getTicketBookingHistory(this.state.page)
          })
        };
      }
    }

    handleTabChange = (TabId) => {
        this.setState({activeTab: TabId});
    };
    showDowloadButton=(stat)=>{
      return !(["PROCESS FAILED","AWAITING PAYMENT","WITHOUT PAYMENT BOOKING","PAYMENT FAILED"].includes(stat))
    }
    async checkAuth(){




      setTimeout(()=>{
        Auth.isLoggedInAsync().then((res) => {
          this.setState({isLoggedIn:res},()=>{
            if(this.state.isLoggedIn===true){
              this.props.getTicketBookingHistory(this.state.page)
            }
          })
        });
      },1000)
    }
    //this is to get the download invoice url for the given bil id
    getInvoiceDownloadUrl=(id)=>{
      return process.env.REACT_APP_BASE_URL+"event-api"+process.env.REACT_APP_LAST_URL+process.env.REACT_APP_Extension+"/api/booking/invoice/"+id;
    }
    renderDownloadLinkForMobile=(id,stat)=>{
      if(window.screen.width>576) return 
      if(!this.showDowloadButton(stat))return;
      return <a href={this.getInvoiceDownloadUrl(id)} target='blank'>
        Download
      </a>
    }

    render() {
      const {ticketBookingHistoryData} = this.props;
      const {isLoggedIn,data} = this.state;
        return (
            <div className='billHistory-container'>
              <div className="container mb-5 px-s-0">
                <PageTitle title={'Booking History | Event | Global Garner'} />
                  <TopBar title={"Bill History"} backClick={this.props.back}
                    static
                    breadcrumbs={[
                      {label:'Booking History',to:'/booking-history'},
                    ]}/>
                  <br/>

                  <Card className='border bp3-elevation-1'>
                    <div className='bill-tabs'>
                      <Fragment>

                              {isLoggedIn===false?
                                <div className='mt-5 mb-5'>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <NoLogin imageShow={true} homeLink={false} />
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                </div>
                              :ticketBookingHistoryData===null || isLoggedIn === null
                                ?
                                <Loading/>
                                :
                                data.length===0?
                                  <div className='col-12 col-xl-12 py-2'>
                                    <div className='p-4'>
                                      <center className='mb-5 mt-5 '>
                                        <img src={require("../images/billhistory.svg")} alt=''/>

                                          <p className='mt-3 mb-2'>No ticket booking history found!</p>

                                      </center>
                                    </div>
                                  </div>
                                  :

                                <InfiniteScroll
                                  dataLength={data.length}
                                  next={this.fetchMoreData}
                                  hasMore={true}
                                  loader={data.length+1 <= ticketBookingHistoryData.total
                                    ?
                                    <div className='mt-1'><Loader/></div>
                                    :null
                                  }
                                  >
                                <table className="bp3-html-table table-borderless mt-4">
                                <thead className="thead-light">
                                  <tr>
                                    <th width="130">Event Name</th>
                                    <th width="100">User Name</th>
                                    <th width="100">Address</th>
                                    <th width="100">Start Date</th>
                                    <th width="100">Booking Date</th>
                                    <th width="100">Price</th>
                                    <th width="100">Ticket Type</th>
                                    <th width="100">Status</th>
                                  </tr>
                                </thead>
                    					<tbody className=''>
                    						{data.map((bill) =>{
                    							return(
                    								<tr key={bill.order_id}>
                    									<td>
                    										<div className="border-rounded">
                                        <p className="m-0 text-left">{bill.event_name}</p>
                                          {/* <Link to={'/event/'+bill.slug}>
                                            <img
                                              src={bill.image}
                                              className='img-fluid rounded-circle'
                                              style={{height: '70px',width:'70px'}}
                                              alt=""
                                              onError={(ev)=>{
                                                ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                              }}
                                              />
                                          </Link> */}
                    										</div>
                    									</td>
                    									<td>
                                      <p className="m-0 text-left">{bill.first_name+" "+bill.last_name}</p>
                                        {/* <Link to={'/event/'+bill.slug}>
                    									   	<p className="m-0 text-left">{bill.event_name}</p>
                                        </Link> */}
                    									</td>
                    									<td >
                    										<p className='bill-address'>{bill.event_address}</p>
                                          {this.renderDownloadLinkForMobile(bill.order_id,bill.status)}
                    									</td>
                                      <td className="text-nowrap">
                    										{bill.start_date}
                    									</td>
                                      <td className="text-nowrap">
                    										{bill.created_date}
                    									</td>
                                      <td className="text-nowrap">
                                        {Helper.formatMoney(bill.total_amount)}
                                      </td>
                    									<td className="text-nowrap">
                    										{bill.ticket_type}
                    									</td>
                    									<td>
                    										<div className={"text-"+bill.color}>
                                          {bill.status}
                                          <br/>
                                          {
                                            (this.showDowloadButton(bill.status))?<a href={this.getInvoiceDownloadUrl(bill.order_id)} target='blank'>
                                            Download receipt 
                                          </a>:null
                                          }

                    										</div>
                    									</td>

                    								</tr>
                    							)
                    						})}
                    					</tbody>
                            </table>
                          </InfiniteScroll>
                        }
                      </Fragment>
                    </div>
                  </Card>


                  <br/>
              </div>

            </div>
          )
        }
    }
