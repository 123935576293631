import React, {Component, Fragment} from 'react';
import PrivateRoute from './PrivateRoute';
import { Switch } from 'react-router-dom';
import {Notfound, Maintance, GgUtils} from "gg-react-utilities";
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import 'react-input-range/lib/css/index.css';
import Home from '../Home';
import BookNow from '../BookNow';
import BookingDetail from '../BookingDetail';
import Payment from "../Payment";
import BookingHistory from '../bookingHistory'

export default class Root extends Component{

    componentDidMount(){

      if(process.env.REACT_APP_ENV==='production'){
        GgUtils.sentryScripts(process.env.REACT_APP_SENTRY_CODE);
        // GgUtils.googleAnalyticalScripts(process.env.REACT_APP_GOOGLE_AUTHENTICATE_CODE);
      }
    }

    render(){
        return(
          <Fragment>
            {process.env.REACT_APP_IS_MAINTENANCE_MODE ==='true'?
              <Switch>
                <PrivateRoute path="*" component={Maintance} />
              </Switch>
              :
              <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/event/:event_slug" component={BookingDetail} />
                <PrivateRoute exact path="/bookNow" component={BookNow} />
                <PrivateRoute exact path='/payment/:status' component={Payment}/>
                <PrivateRoute exact path='/booking-history' component={BookingHistory}/>
                <PrivateRoute path="*"  component={Notfound}/>
              </Switch>
            }
          </Fragment>
        );
    }
}
