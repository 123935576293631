
export const eventDetails = (sources)=>{

    return {
        type: 'EVENT_DETAIL',
        sources:sources
    }
}

export const eventData = (sources)=>{

    return {
        type: 'EVENT_DATA_DETAIL',
        sources:sources
    }
}

export const priceData = (sources)=>{

    return {
        type: 'PRICE_DATA',
        sources:sources
    }
}

export const ticketAvailibility = (sources)=>{

    return {
        type: 'TICKET_AVAILIBILITY',
        sources:sources
    }
}

export const selectTicketData = (sources)=>{

    return {
        type: 'SELECT_TICKET_DATA',
        sources:sources
    }
}

export const ticketPreorder = (sources)=>{

    return {
        type: 'TICKET_PREORDER',
        sources:sources
    }
}

export const ticketBookingHistoryData = (sources)=>{

    return {
        type: 'TICKET_BOOKING_HISTORY_DATA',
        sources:sources
    }
}

export const setFilterValue = (value)=>{
    return {
        type: 'SET_VALUE',
        value:value
    }
}
export const setFilterPage = (page)=>{
    return {
        type: 'SET_PAGE',
        page:page
    }
}
export const setFilterDay = (day)=>{
    return {
        type: 'SET_DAY',
        selectedDay:day
    }
}