import React, {Component, Fragment} from 'react';
import renderHTML from 'react-render-html';
import {Button, Card} from "@blueprintjs/core";
import {LocationOnRounded} from '@material-ui/icons';
import {PageTitle, Auth} from 'gg-react-utilities';
import moment from 'moment';
import Loading from './loading';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: {
                min: 0,
                max: 20
            },
            isDateOpen: true,
            isLoggedIn: null,
            price_min_value: 100,
            price_max_value: 1000,
            isChecked: false,
            Date: ['Today', 'Tomorrow', 'Weekend']
        }
    }

    componentDidMount() {
        this
            .props
            .getEventDetailData(this.props.eventSlug);
        this.checkAuth();
    }

    async checkAuth() {
        setTimeout(() => {
            Auth
                .isLoggedInAsync()
                .then((res) => {
                    this.setState({isLoggedIn: res})
                });
        }, 1)
    }

    componentWillReceiveProps(nextProps) {}
    //this is to get the date of the events
    getEventsDate = (eventDetailData) => {
        if (eventDetailData == null) 
            return "";
        let start_time = moment(eventDetailData.start_time).format('ll')
        let end_time = moment(eventDetailData.end_time).format('ll');
        if (start_time === end_time) {
            return start_time
        }
        return "" + start_time + " to " + end_time;
    }
    //this is to render ticket button
    renderTicektButton(event_date) {
        let isExpire = (new Date().getTime() > event_date.getTime())
        let message = '';
        if (!isExpire) {
            //check ticekts are available or not
            let count =+ this.props.eventDetailData.total_ticket
            if (count > 0) {
                return <> <Button
                    text='Register Now'
                    className='bp3-button bp3-intent-primary pull-right mt-2'
                    onClick={() => {
                    if (new Date().getTime() < new Date(this.props.eventDetailData.end_time).getTime()) {
                        if (this.state.isLoggedIn === true) {
                            this
                                .props
                                .getEventAvailibility(this.props.eventDetailData.event_id);
                            if (this.props.checkTicketAvailibility !== null) {
                                if (this.props.checkTicketAvailibility) {
                                    let obj = {
                                        ticketType: this.props.eventDetailData.ticket_type,
                                        eventId: this.props.eventDetailData.event_id,
                                        eventName: this.props.eventDetailData.event_name,
                                        paymentStatus: this.props.eventDetailData.is_online_payment_status,
                                        eventSlug: this.props.eventSlug,
                                        eventDetail: this.props.eventDetailData
                                    }
                                    this
                                        .props
                                        .nextPage('/bookNow', obj)
                                }
                            }
                        } else {
                            Auth.login(true);
                        }
                    }
                }}/>
                {/* <p className='text-primary address'> {(+this.props.eventDetailData.total_ticket>0)?this.props.eventDetailData.ticket_status:""}</p> */
                } < />
        }else{
          message=this.props.eventDetailData.ticket_status
        }
      }else{
        message="Event is completed"
      }
      return  <p className='text-danger address'>{message}</p >
        }
        render() {
            const {eventDetailData, checkTicketAvailibility, nextPage, filter} = this.props;
            let qstr = window
                .location
                .href
                .split("?")[1]
            qstr = "?" + qstr + "&back=1"; //to indicate we come back  from detail page to list page
            let dateStr = this.getEventsDate(eventDetailData);
            let button = null;
            if (eventDetailData !== null) {
                button = this.renderTicektButton(new Date(eventDetailData.end_time.replace(' ', 'T')));
            }
            return (
                <Fragment>
                    {eventDetailData === null
                        ? <Fragment>
                                <div className="container">
                                    <div className="event_banner">
                                        <div className="row d-flex justify-content-end back-btn">
                                            <Button
                                                className="back-icon hidden-xs"
                                                onClick={() => {
                                                nextPage('/' + qstr)
                                            }}>
                                                <span className="bp3-icon bp3-icon-chevron-left"></span>&nbsp; Back
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="container pt-4 pb-5">
                                    <Loading/>
                                </div>
                            </Fragment>
                        : <Fragment>
                            <div className="">
                                <div
                                    className="event_banner"
                                    style={{
                                    backgroundImage: `url(${eventDetailData.image})`
                                }}>
                                    <div className="row d-flex justify-content-end back-btn">
                                        <Button
                                            className="back-icon hidden-xs"
                                            onClick={() => {
                                            nextPage('/' + qstr)
                                        }}>
                                            <span className="bp3-icon bp3-icon-chevron-left"></span>&nbsp; Back
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="container pt-4 pb-5">
                                <PageTitle title={eventDetailData.event_name + ' | Event | Global Garner'}/>
                                <div className='col-12 mb-4 Gift_Block'>
                                    <Card className="px-4">
                                        <div className='row'>
                                            <div className='col-md-9 col-sm-8 col-12'>
                                                <h1>{eventDetailData.event_name}</h1>
                                            </div>
                                            <div className='col-md-3 col-sm-4 col-12'>
                                                <div
                                                    style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    {button}

                                                </div>
                                                {/* {new Date().getTime()<new Date(eventDetailData.end_time)?
                                        <Button
                                            text='Book Ticket'

                                            className='bp3-button bp3-intent-primary pull-right mt-2'
                                            onClick={() => {
                                              if(new Date().getTime()<new Date(eventDetailData.end_time)){
                                                if(this.state.isLoggedIn===true){
                                                  this.props.getEventAvailibility(eventDetailData.event_id);
                                                  if(checkTicketAvailibility!==null){
                                                    if(checkTicketAvailibility){
                                                      let obj = {
                                                        ticketType: eventDetailData.ticket_type,
                                                        eventId:eventDetailData.event_id,
                                                        eventName:eventDetailData.event_name,
                                                        paymentStatus:eventDetailData.is_online_payment_status,
                                                        eventSlug:this.props.eventSlug,
                                                      }
                                                      this.props.nextPage('/bookNow',obj)
                                                    }
                                                  }
                                                } else {
                                                  Auth.login(true);
                                                }
                                              }
                                            }}/>
                                        :
                                        <Button
                                            text='Book Ticket'

                                            className='bp3-button bp3-intent-primary pull-right mt-2'
                                            disabled/>
                                      } */}

                                            </div>
                                        </div>
                                        <hr/>
                                        <div className='row pt-2'>
                                            <div className='col-lg-4 col-md-3 col-sm-4 col-12'>
                                                
                                                <p className='text-primary d-flex'>
                                                    <div style={{fontSize:'18px'}}>Start Time :</div>
													<div className="address">&nbsp;&nbsp;{moment(eventDetailData.start_time).format('Do MMM, YYYY hh:mm A')}</div>
                                                </p>
												<p className='text-primary d-flex'>
												<div style={{fontSize:'18px'}}>End Time : </div>
													<div className="address">&nbsp;&nbsp;&nbsp;&nbsp;{moment(eventDetailData.end_time).format('Do MMM, YYYY hh:mm A')}</div>
                                                </p>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-5 col-12 d-flex'>
                                                <LocationOnRounded/>
                                                <p className='Text-Capitalize text-muted address'>
                                                    {eventDetailData.event_address + ', ' + eventDetailData.city + ', ' + eventDetailData.country}
                                                </p>
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-3 col-12'>
                                                {eventDetailData.ticket_type.length > 0
                                                    ? <p className='text-muted'>
                                                            &#8377; {eventDetailData.ticket_type[eventDetailData.ticket_type.length - 1].amount}
                                                            {eventDetailData.ticket_type.length > 1
                                                                ? 'Onwards'
                                                                : ''}</p>
                                                    : <p className='text-success'>Free</p>
}
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className='col-12 Gift_Desc'>
                                    <Card>
                                        <div className='py-3'>
                                            <p className='text-dark address'>{renderHTML(eventDetailData.event_description)}</p>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </Fragment>
}
                </Fragment>
            );
        }
    }
