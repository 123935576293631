import React, {Component} from 'react';

export default class LoadingComponent extends Component{

    render(){

        return(
            <table className="bp3-html-table table-borderless mt-4">
              <thead className="thead-light">
                <tr>
                  <th width="120">Event Detail</th>
                  <th width="100">Event Name</th>
                  <th width="100">Address</th>
                  <th width="100">Start Date</th>
                  <th width="120">Booking Date</th>
                  <th width="100">Price</th>
                  <th width="100">Ticket Type</th>
                  <th width="100">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "95%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "95%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "95%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "95%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "95%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "95%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "95%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "95%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                </tr>
                <tr>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "100%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "80%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "90%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "95%", height: "50px"}}/></td>
                  <td><div className="bp3-skeleton" style={{width: "85%", height: "50px"}}/></td>
                </tr>
              </tbody>
            </table>
        );

    }

}
