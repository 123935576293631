import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import HomeComponent from '../components/HomeComponent';
import { getEventData, getPriceData } from '../../core/actions/action';
import { eventDetails, priceData,setFilterDay,
    setFilterValue,
    setFilterPage } from '../../core/actions';

const mapStateToProps = state => {
    return{
        eventList   : state.Home.eventList,
        priceData   : state.Home.priceData,
        // page        : state.Filters.page,
        // selectedDay : state.Filters.selectedDay,
        // value       : state.Filters.value,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        setFilterValue :(value)=>{ dispatch(setFilterValue(value))},
        setFilterPage  :(page)=>{dispatch(setFilterPage(page))},
        setFilterDay   :(day)=>{ dispatch(setFilterDay(day))},
        getEventData:(page,price,key)=>{
          // dispatch(eventDetails(null));
            return getEventData(page,price,key)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(eventDetails(sources.data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(eventDetails(null));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        },

        getPriceData:() => {
          dispatch(priceData(null));
            return getPriceData()
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(priceData(sources.data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(priceData(null));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
